<template>
  <div id="app">
    <div class="row">
      <div class="col-md-12 text-start">
        <h5>
          <a
            class="btn btn-sm btn-outline-secondary"
            @click="$router.push('/Cruds')"
          >
            <i class="fa fa-arrow-left"> </i> </a
          ><strong> Tipos de Gabinete Inferior</strong>
        </h5>
        <hr />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12 text-end">
        <a
          class="btn btn-primary"
          @click="$router.push('/crud/cabinetbelow/add')"
        >
          <i class="fa fa-plus"> </i> Crear Tipo de Gabinete
        </a>
      </div>
      <data-table
        class="text-start mt-4"
        v-bind="parametersTable1"
        @actionTriggered="handleAction"
      />
    </div>

    <b-modal v-model="modalShow" title="Eliminar">
      <div class="row">
        <div>
          <h5>¿Está seguro que desea eliminar este registro?</h5>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 text-end">
          <b-button @click="modalShow = false" class="mx-1 btn-danger btn"
            ><i class="fa fa-times"> </i> &nbsp; Cancelar</b-button
          >
          <b-button variant="primary"
            ><i class="fa fa-save"> </i> &nbsp;Aceptar</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/DataCruds/CabinetBelow";

export default {
  components: {},
  data() {
    return {
      dataList: [],
      modalShow: false,
      idToDelete: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  props: {},
  methods: {
    handleAction(actionName, data) {
      if (actionName == "edit") {
        this.$router.push("/crud/cabinetbelow/edit/" + data.id);
      }
      if (actionName == "delete") {
        this.idToDelete = data.id;
        this.modalShow = true;
      }
    },
    async getData() {
      try {
        const data = await api.getData();
        if (data) {
          this.dataList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    parametersTable1() {
      return {
        data: this.dataList.map((x) => {
          return {
            ...x,
          };
        }),
        lang: "es",
        actionMode: "single",
        actions: ["edit", "delete"],
        columns: [
          {
            key: "id",
            title: "Id",
          },
          {
            key: "description",
            title: "Descripción",
          },
        ],
      };
    },
  },
};
</script>
